import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, logout, db } from "../../firebase";
import { getDoc, doc } from "firebase/firestore"; 
import { useAuthState } from "react-firebase-hooks/auth";
import classes from "../Login/Login.module.css"
import { Button, Checkbox, Col, Row, Form, Input, Layout, Divider} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Typography } from 'antd';


const { Content, Footer } = Layout;
const { Title } = Typography;

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const handleAllowedTools = async (currentUser) => {
        if (currentUser) {
            const userdocument = await getDoc(doc(db, 'users', currentUser.uid));
            if (!userdocument.data().allowedTools.includes('pnta-hub')){
                logout();
                alert("Sie haben keinen Zugang zu diesem Tool. Bitte kontaktieren Sie support-pnta@otto.de");
            } else {
                navigate("/hub");
            }
        } else {
            console.log('No Login detected!')
        }
    };

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }
        if (user) {
            handleAllowedTools(user);
        }
    }, [user, loading]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{ background: '#fdfdfd', display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Row>
                    <Col span={24} >
                        <div className={classes.Logo}></div>
                        <Divider>
                            <Title level={3} style={{color: "#e3051b"}}>
                                HUB
                            </Title>
                        </Divider>

                        <Form name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialvalues={{ remember: true }}
                            autoComplete="off"
                            onFinish={(event) => logInWithEmailAndPassword(email, password)}
                            
                        >
                            <Form.Item
                                label="E-Mail Adresse"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                rules={[{ required: true, message: 'Bitte Emailadresse eintragen.' }]}
                            >
                                <Input prefix={<UserOutlined />} placeholder="Username" name="email"/>
                            </Form.Item>
                            <Form.Item
                                variant="outlined"
                                margin="normal"
                                required
                                name="password"
                                label="Passwort"
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                            >
                                <Input
                                    prefix={<LockOutlined />}
                                    type="password"
                                    placeholder="Passwort"
                                    name="password"
                                />
                            </Form.Item>
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox defaultChecked>
                                    Anmeldedaten merken
                                </Checkbox>
                            </Form.Item>
                            <Form.Item name="submit">
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    Anmelden
                                </Button>
                            </Form.Item>
                            <div>
                                <Link to="/reset">Forgot Password</Link>
                            </div>
                        </Form>
                        <Footer style={{ background: '#fdfdfd', textAlign: 'center' }}>
                            Ant Design ©2022 Created by Ant UED
                        </Footer>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
export default Login;