import classes from './App.module.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Components/Login/Login";
import Hub from "./Components/Home/Home";

import { Divider, Select, Row, Col, Card, Image, Menu } from 'antd';
import { Layout, Typography, Sider } from 'antd';
import { logout } from "./firebase";
import { LogoutOutlined } from '@ant-design/icons';


function App() {

  const { Header, Content, Footer, Sider } = Layout;
  const {Title} = Typography;

  const handleLogOut = () =>{
    logout();
}

const menuItems = [
  { label: ' Abmelden', key: 'lougout', icon: <LogoutOutlined />, onClick: handleLogOut }
];

  return (
    <Layout style={{ minHeight: '100vh' }}>
			<Layout>
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/Hub" element={<Hub />} />
              </Routes>
            </Router>    
			</Layout>
		</Layout>
  );
}

export default App;