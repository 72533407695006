import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { useAuthState } from "react-firebase-hooks/auth";
import '../../../src/index.css'
import { auth, logout } from "../../firebase";
import { Layout, Col, Divider, Row, PageHeader, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";


const style = {
  background: '#fff',
  padding: '8px 0',
  textAlign: 'center',
};

function Hub() {

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const { Content, Footer } = Layout;


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading]);

    const handleLogOut = () =>{
      logout();
  }

  return( 
  
  <Layout>
    <Layout>
    <PageHeader
      title="PNTA HUB"
      subTitle="Dashboard"
      extra={[
        <Button key="2" onClick={handleLogOut}>{<LogoutOutlined />}Abmelden</Button>
      ]}
    >
    </PageHeader>
      <Content
        style={{
          margin: '24px 16px 0',
        }}
      >
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <Divider orientation="center">PNTA Hub</Divider>
      <Row
      gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
    >
      <Col className="gutter-row" span={6}>
        <div style={style}><a href="https://mapping.pnta.de" target="_blank" rel="noreferrer">PNTA Mapping</a></div>
      </Col>
      <Col className="gutter-row" span={6}>
      <div style={style}><a href="https://pnta.de" target="_blank" rel="noreferrer">PNTA Booking</a></div>
      </Col>
      <Col className="gutter-row" span={6}>
        <div style={style}><a href="https://segment-sharing.pnta.de" target="_blank" rel="noreferrer">PNTA GEO Segment Sharing</a></div>
      </Col>
      <Col className="gutter-row" span={6}>
        <div style={style}><a href="https://heatmap-detail-report-dot-rmu-pnta-atlas.ey.r.appspot.com/login" target="_blank" rel="noreferrer">PNTA Heatmap Detail Report</a></div>
      </Col>
      <Divider orientation="center">Admin Tools</Divider>
      <Col className="gutter-row" span={6}>
        <div style={style}><a href="https://um.pnta.de" target="_blank" rel="noreferrer">User Management</a></div>
      </Col>
      <Col className="gutter-row" span={6}>
        <div style={style}><a href="http://monitoring.pnta.de:3000/login" target="_blank" rel="noreferrer">Monitoring</a></div>
      </Col>
    </Row>
    </div>
    </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        OTTO Retail Media
      </Footer>
    </Layout>
  </Layout>
)};
      

export default Hub;